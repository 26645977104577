var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(_vm.status)?_c('div',{attrs:{"id":"tracking-list"}},[(_vm.userData.role === 'PLSYR' && _vm.$vuetify.breakpoint.xsOnly)?_c('customer-list'):_vm._e(),_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg',attrs:{"tile":"","flat":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.breakpoint.mdAndDown ? 'white' : 'transparent',"flat":!_vm.$vuetify.breakpoint.smAndDown,"height":_vm.$vuetify.breakpoint.smAndDown ? 57 : 87}},[_c('v-toolbar-title',{staticClass:"text-body-2 text-md-h6 font-weight-medium"},[_c('v-icon',{attrs:{"large":!_vm.$vuetify.breakpoint.smAndDown,"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiOrderBoolAscending)+" ")]),_vm._v(" Açık Siparişler ")],1),_c('v-spacer'),(!_vm.$vuetify.breakpoint.smAndDown)?_c('download-excel',{attrs:{"data":_vm.json_data,"fields":_vm.json_fields,"name":"ACIK_SIPARISLER__ANLAS.xls"}},[_c('v-btn',{attrs:{"plain":"","text":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")]),_vm._v(" İndir ")],1)],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.trackingListTable,"items-per-page":15,"fixed-header":"","height":_vm.dataTableHeight,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.VALIDFROM",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption font-weight-medium"},[_vm._v(" "+_vm._s(item.VALIDFROM.slice(0, 10))+" ")])]}},{key:"item.DOCNUM",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-caption font-weight-medium",attrs:{"color":"chip","label":"","small":""}},[_vm._v(" "+_vm._s(item.DOCNUM)+" ")])]}},{key:"item.MATERIAL",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-caption font-weight-medium",attrs:{"color":"chip","label":"","small":""}},[_vm._v(" "+_vm._s(item.MATERIAL)+" ")])]}},{key:"item.GRCNAME1",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption font-weight-medium",staticStyle:{"width":"200px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(item.GRCNAME1)+" ")])]}},{key:"item.MTEXT",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption font-weight-medium"},[_vm._v(" "+_vm._s(item.MTEXT)+" ")])]}},{key:"item.QUANTITY",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption font-weight-medium"},[_vm._v(" "+_vm._s(item.QUANTITY)+" ")])]}},{key:"item.AVAILQTY",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.AVAILQTY)+" ")])]}},{key:"item.SPRICE",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"success--text text-caption font-weight-medium"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.SPRICE))+" ")])]}},{key:"item.GRANDTOTAL",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"success--text text-caption font-weight-medium"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.GRANDTOTAL))+" ")])]}},{key:"item.adres",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ADDRESSLINE1)+" - "+_vm._s(item.NAME1)+" ")]}}],null,true)})],1)],1):_c('div',[(_vm.userData.role === 'PLSYR' && _vm.$vuetify.breakpoint.smAndDown)?_c('customer-list'):_vm._e(),(_vm.userData.role === 'PLSYR' && !_vm.$store.state.plasiyerCustomer)?_c('v-alert',{attrs:{"tile":"","type":"warning"}},[_vm._v(" Lütfen yukarıdan bir müşteri seçin ")]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }